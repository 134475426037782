<template>
  <div>
    <section class="container bottom--helper">
      <v-row class="d-flex justify-center flex-column align-center">
        <div class="d-flex align-self-start pt-2 pb-2">
          <Breadcrumb class="conteudos_tematicos_section_bc-color">
            <template v-slot:breadcrumbLinks>
              <router-link
                tag="p"
                :to="{ name: link.href }"
                v-for="(link, idx) in breadcrumbItems"
                :key="idx"
              >
                {{ link.text }}
              </router-link>
            </template>
          </Breadcrumb>
        </div>
        <div
          class="d-flex align-self-center justify-center align-center mt-md-5"
        >
          <img
            width="40%"
            src="../../assets/conteudos_casas_home_08.png"
            alt=""
          />
        </div>
      </v-row>
    </section>

    <section class="container bottom--helper">
      <v-row class="d-flex justify-space-between align-center">
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card
              borderColor="#426E51"
              title="MAPEAMENTO DAS VIAS"
              titleColor="#426E51"
              title_alignment="center"
            >
              <template v-slot:conteudo>
                <p>
                  Rotas utilizadas pelas comunidades atingidas até o rompimento
                  da barragem de Fundão e caminhos alternativos percorridos após
                  o desastre criminoso.
                </p>
                <p>
                  Os caminhos ou estradas do território de Mariana foram se
                  concretizando principalmente ao longo das primeiras décadas do
                  século XVIII. Ressalta-se que algumas picadas e caminhos
                  abertos por indígenas já existiam pelo território mineiro e, a
                  partir do século XVII, foram aproveitados na formação e
                  exploração do território que viria a ser as Minas Gerais. No
                  início do século XVIII, já havia muitos caminhos abertos no
                  território de exploração do ouro e de diamantes, por onde eram
                  transportados esses bens extraídos. Para controle da Coroa
                  portuguesa sobre o que era produzido na região das minas,
                  foram criados pontos de cobrança de impostos quando as
                  estradas cruzavam as fronteiras entre as capitanias
                  existentes. Com o objetivo de impedir que o ouro e os
                  diamantes extraídos no Brasil fossem contrabandeados, ou seja,
                  não passassem pelo controle de Portugal e não pagassem
                  impostos, foram estabelecidos caminhos que deveriam ser
                  seguidos oficialmente, chamados de Estradas Reais, nas quais
                  estavam instalados os pontos de cobrança de impostos. Os
                  caminhos oficiais de Minas estão entre os mais significativos
                  e conhecidos do Brasil, denominados como Caminho Velho e
                  Caminho Novo.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="d-flex justify-center align-center flex-column"
        >
          <img width="100%" src="../../assets/conteudos_vias_acesso_01.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Placa e ligação da Estrada Real em Camargos. Foto: Flora Passos,
            2016.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container bottom--helper">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="11" md="12" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51" titleColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  A descrição desses trajetos e o conhecimento sobre eles
                  ocorrem além da transmissão oral, por meio de documentos
                  escritos a partir da transição do século XVII para o XVIII,
                  por vezes acompanhados de mapas realizados de forma precária.
                  A partir do século XVIII, estes passaram a conter informações
                  de maior precisão, caracterizando-se como uma cartografia mais
                  científica.
                </p>
                <p>
                  Mariana consiste em um território de referência dentro da
                  história de Minas Gerais, já que sua formação, ocupação e
                  evolução socioeconômica fundem-se com a própria história do
                  estado, tanto no que diz respeito à sede, quanto às
                  comunidades que integram este território. A ocupação foi
                  gerada pela exploração de ouro, quando expedições que partiam
                  de São Paulo avançaram por Minas Gerais. Simultaneamente à
                  formação da sede, outros arraiais foram surgindo e alguns se
                  transformaram em freguesia quando Mariana se tornou vila, em
                  1711. A maior parte da ocupação do território de Mariana
                  ocorrida no século XVIII se concentrou nas margens do Ribeirão
                  do Carmo, bem como ao longo do Rio Piranga, Gualaxo do Sul e
                  Gualaxo do Norte.
                </p>
                <p>
                  A descoberta de novos pontos para extração do metal levou os
                  grupos de garimpeiros a avançar pelo território do Rio Gualaxo
                  do Norte, somada à necessidade da produção de alimentos. Desta
                  forma, paralelamente à mineração, eram desenvolvidas
                  atividades de plantio e criação de animais, cuja produção era
                  voltada para o povoamento onde ocorria a extração e para a
                  sede de Mariana.
                </p>
                <p>
                  Os produtos locais passaram a concorrer com as mercadorias que
                  vinham de terras distantes do Brasil colonial, trazidos por
                  tropas que viajavam pelas estradas que ligavam Salvador, São
                  Paulo e Paraty. Entre os cultivos, destacavam-se o milho, o
                  feijão e a cana de açúcar. Quanto à atividade pecuária, se
                  sobressaia a criação de suínos, e havia ainda a fabricação
                  artesanal de artigos como a cachaça ou aguardente. As
                  comunidades desenvolveram uma capacidade de geração de
                  economia e crescimento do território que consumia e fornecia
                  os gêneros alimentícios.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container bottom--helper">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="11" md="12" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51" titleColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  As rotas eram percorridas por tropeiros transportando
                  mercadorias e pessoas escravizadas da região das Minas,
                  principalmente para os portos do Rio de Janeiro. O trecho da
                  Estrada Real que ligava Sabará à Vila do Carmo (Mariana),
                  margeando a Serra do Caraça, passava pelo povoado de
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'bento_rodrigues' }"
                  >
                    Bento Rodrigues</router-link
                  >, caracterizado por fazendas onde ocorriam atividades
                  agropecuárias conjugadas com a extração de ouro. Uma rua
                  cortava o arraial na direção norte e sul e fazia a ligação de
                  Bento Rodrigues com as freguesias de
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'sobre_camargos' }"
                    >Camargos</router-link
                  >
                  (a sul) e do Inficcionado (atual
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'sobre_santa_rita' }"
                    >Santa Rita Durão</router-link
                  >, a norte). Essa rua trata-se da Rua São Bento, que balizada
                  pelas torres das duas capelas, consistiu no eixo de formação
                  da comunidade.
                </p>

                <p>
                  A partir de meados do século XVIII, com o declínio da
                  atividade aurífera, cada localidade do território marianense
                  teve consequências diferentes, a depender do quanto essa
                  atividade econômica era significativa e do quanto haviam sido
                  desenvolvidas outras paralelamente. Tanto nos casos de
                  povoamentos que tiveram sua adaptação mais rápida quanto nos
                  que mais lentamente criaram outros meios de se manter, as
                  atividades agropecuárias e da manufatura passaram a ter maior
                  importância na economia mineira ao longo do século XIX. O
                  sustento dos povoamentos passou a acontecer a partir da
                  produção de grãos, da criação de gado e da fabricação de
                  cachaça. Eram fabricados ainda tecidos de panos grossos, peças
                  de ferro produzidas nas pequenas forjas das fazendas e
                  alimentos como o toucinho defumado, que faziam parte do
                  consumo tanto de pessoas livres quanto das escravizadas.
                </p>
                <p>
                  Conforme exposto, as estradas do território estiveram
                  diretamente ligadas à sua formação e desenvolvimento
                  socioeconômico, inicialmente vinculadas à extração de ouro.
                  Conforme a economia se adaptou às mudanças ocorridas, outras
                  rotas foram sendo abertas, proporcionando a comercialização,
                  as trocas e os acessos entre as comunidades ali estabelecidas.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container bottom--helper">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="11" md="12" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51" titleColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  Até o rompimento da barragem de Fundão, o território de
                  Mariana e suas comunidades se mantiveram ativos mantendo as
                  atividades sociais, econômicas e culturais, interligados pelas
                  estradas locais de acesso, tratando-se de uma rede de fomento,
                  longe de serem distritos, sub distritos e localidades
                  isoladas, mas sim com desenvolvimento e crescimento baseado no
                  modelo de produção da agricultura familiar.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container bottom--helper">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="12"
          md="6"
          class="d-flex flex-column justify-center align-center"
        >
          <img width="100%" src="../../assets/conteudos_vias_acesso_02.png" />
          <p style="font-size: 0.5em; text-align: center;">
            Estrada entre as comunidades de Mariana. Foto: Flora Passos, 2016.
          </p>
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="d-flex flex-column justify-center align-center"
        >
          <img width="100%" src="../../assets/conteudos_vias_acesso_03.png" />
          <p style="font-size: 0.5em; text-align: center;">
            Moradores percorrendo a cavalo em estrada de Paracatu de Baixo.
            Foto: Wan Campos, 2018.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container bottom--helper">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="11" md="12" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card
              borderColor="#426E51"
              titleColor="#426E51"
              title="SITUAÇÃO APÓS O ROMPIMENTO"
            >
              <template v-slot:conteudo>
                <p>
                  A lama de rejeitos proveniente do rompimento da barragem de
                  Fundão se estendeu pelo território ao longo do Rio Gualaxo do
                  Norte, devastando o que nele havia, como plantações, criação
                  de animais, casas, edificações de uso rural e equipamentos de
                  uso público. Nesta página, enfatizam-se as vias, estradas de
                  acesso entre as comunidades, bem como caminhos de ligação com
                  a sede de Mariana e outros municípios, a exemplo de Santa
                  Bárbara.
                </p>
                <p>
                  Parte da Estrada Real foi devastada, e seu percurso ficou
                  interrompido. A região onde se localizam
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'sobre_santa_rita' }"
                    >Santa Rita Durão</router-link
                  >,
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'bento_rodrigues' }"
                  >
                    Bento Rodrigues</router-link
                  >,
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'sobre_camargos' }"
                    >Camargos</router-link
                  >
                  e os municípios vizinhos a norte como Santa Bárbara e Catas
                  Altas faziam parte de um circuito cultural e turístico em
                  virtude das riquezas naturais e patrimoniais, estas herdadas
                  do período colonial. Moradores e visitantes percorriam
                  estradas e trilhas atraídos pela paisagem natural em busca de
                  aventura e lazer, principalmente ciclistas e motociclistas.
                  Pousadas, restaurantes e bares faziam parte da estrutura,
                  havendo nos caminhos entre o entorno montanhoso, cachoeiras,
                  córregos, rios e praias de descanso.
                </p>
                <p>
                  Com o rompimento da barragem de Fundão, a ligação entre essas
                  comunidades foi impossibilitada pela destruição de Bento
                  Rodrigues, incluindo suas vias internas. Soma-se a isso o
                  controle exercido pela Samarco, havendo guaritas nas entradas
                  das ruínas do núcleo urbano. Além disso, Bento teve parte de
                  seu território alagado pelo Dique S4 e, consequentemente,
                  desapareceu o trecho da estrada que conduzia a Santa Rita
                  Durão, a Santa Bárbara e a Catas Altas, passando pela
                  comunidade. Desta forma, Camargos também foi prejudicada
                  quanto à atividade turística, perdendo a conexão com a vizinha
                  Bento Rodrigues e demais comunidades situadas a norte.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container bottom--helper">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="12"
          md="6"
          class="d-flex flex-column justify-center align-center"
        >
          <img width="100%" src="../../assets/conteudos_vias_acesso_04.png" />
          <p style="font-size: 0.5em; text-align: center;">
            Área restrita e acesso impossibilitado pelo Dique S4 em Bento
            Rodrigues. Foto: Joice Valverde / Jornal A Sirene, 2019.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container bottom--helper">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="11" md="12" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51" titleColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  Outras atividades econômicas foram interrompidas, a exemplo do
                  comércio e trocas entre produtores do setor agropecuário
                  próximos, visto que as longas distâncias que passaram a ser
                  percorridas pelos desvios inviabilizaram financeiramente a
                  continuidade dos negócios.
                </p>
                <p>
                  O território possui, desde sua ocupação original, a extração
                  de ouro como atividade econômica em paralelo às demais, sendo
                  a região de
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'sobre_bicas' }"
                    >Bicas</router-link
                  >
                  e
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'sobre_ponte_do_gama' }"
                    >Ponte do Gama</router-link
                  >
                  os principais trechos do Rio Gualaxo do Norte com maior
                  presença do metal. O acesso por Bento Rodrigues ficou
                  impossibilitado para os moradores de Santa Rita Durão que
                  exerciam a atividade de garimpo artesanal, sendo
                  obrigatoriamente desviado o trajeto pela rodovia MG 129,
                  passando por Antônio Pereira, distrito de Ouro Preto.
                  Trabalhadores do setor do garimpo de vários locais perderam o
                  acesso e o local de trabalho, sejam eles de Antônio Pereira,
                  Camargos, Ponte do Gama,
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'sobre_paracatu_de_cima' }"
                    >Paracatu de Cima</router-link
                  >,
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'sobre_paracatu_de_baixo' }"
                    >Paracatu de Baixo</router-link
                  >,
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'sobre_aguas_claras' }"
                    >Águas Claras</router-link
                  >,
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'sobre_campinas' }"
                    >Campinas</router-link
                  >,
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'sobre_pedras' }"
                    >Pedras</router-link
                  >,
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'sobre_monsenhor' }"
                    >Monsenhor Horta</router-link
                  >, incluindo ainda os da sede de Mariana.
                </p>
                <p>
                  Outra atividade tradicionalmente desenvolvida no território é
                  a criação de gado, principalmente o bovino leiteiro.
                  Produtores de várias comunidades possuíam como referência a
                  Associação de Produtores de Leite de Águas Claras e Região,
                  para onde o leite era conduzido. Principalmente as comunidades
                  de Ponte do Gama, Paracatu de Cima, Paracatu de Baixo,
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'sobre_borba' }"
                    >Borba</router-link
                  >, Pedras,
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'sobre_braunas' }"
                    >Braúnas</router-link
                  >, Campinas e
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'sobre_mata_chaves' }"
                    >Mata do Chaves</router-link
                  >
                  não tiveram como escoar a produção, já que as pontes e
                  estradas que conduziam a Águas Claras foram interrompidas.
                  Peças de queijo e litros de leite ficaram comprometidas para o
                  consumo, devido ao tempo em que ficaram armazenados, sem ter
                  como chegar aos compradores, tanto de locais vizinhos como da
                  sede de Mariana.
                </p>
                <p>
                  Da mesma forma, a produção agrícola também foi prejudicada,
                  seja ela para comercialização ou para consumo das próprias
                  famílias. Muitos agricultores relataram durante o processo de
                  cadastramento a dificuldade de receber insumos e de comprar
                  mantimentos nos comércios de comunidades próximas. O acesso à
                  sede de Mariana também ficou impossibilitado, tendo como
                  exemplo os produtores de Águas Claras, que para chegar até
                  Mariana, obrigatoriamente recorreram ao desvio passando por
                  Fonseca, até chegar na rodovia MG 129 e seguir por ela
                  passando por Antônio Pereira. Comunidades localizadas na
                  margem sul do Rio Gualaxo do Norte recorreram por sua vez ao
                  desvio que passa por Furquim, aumentando também
                  consideravelmente a extensão do trajeto.
                </p>
                <p>
                  A atividade de plantio de eucalipto e produção de carvão
                  vegetal destaca-se entre as desenvolvidas nas comunidades do
                  território e, da mesma forma, que as outras teve prejuízos,
                  sendo interrompida em grande parte dos casos. Conforme
                  relatado pelas pessoas atingidas desse ramo, o custo do
                  transporte até os compradores passando pelas longas distâncias
                  dos desvios encareceu o produto de modo a não compensar a
                  venda, interferindo assim no ciclo de crescimento, corte da
                  planta, queima e escoamento. Diante disso, todo um ciclo de
                  produção foi perdido na época do rompimento, e os prejuízos
                  inviabilizaram a continuidade da prática.
                </p>
                <p>
                  As relações criadas entre as comunidades do território, além
                  das questões econômicas tradicionalmente realizadas, dizem
                  respeito também ao aspecto religioso e de festividades. Ao
                  longo dos séculos, desde sua formação, no território eram
                  realizadas manifestações culturais e religiosas, em que
                  moradores das comunidades vizinhas participavam das
                  festividades umas das outras.
                </p>
                <p>
                  A disputa pelo território e suas riquezas, presente na região
                  de Mariana desde sua formação, foi ainda mais acirrada no
                  contexto da exploração do minério de ferro, caracterizada pela
                  desigualdade de poder e atuação entre as comunidades locais e
                  as empresas mineradoras. O conflito ficou evidenciado com o
                  rompimento da barragem de Fundão em 05 de novembro de 2015,
                  que causou a destruição do território e interrompeu a trama de
                  relações criadas entre as comunidades ao longo de 300 anos.
                </p>
                <p>
                  A destruição de extensões de terra de comunidades como
                  Paracatu de Cima, Campinas, Borba, Pedras, Ponte do Gama,
                  Bicas e Camargos, não gerou expulsão imediata do território,
                  contudo ao longo dos anos pós-rompimento essas comunidades
                  viram o esvaziamento populacional acontecer.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container bottom--helper">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="12"
          md="6"
          class="d-flex flex-column justify-center align-center"
        >
          <img width="100%" src="../../assets/conteudos_vias_acesso_05.png" />
          <p style="font-size: 0.5em; text-align: center;">
            Escola Municipal de Campinas em estado de degradação. Foto: Flora
            Passos, 2018.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container bottom--helper">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="11" md="12" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51" titleColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  Ressalta-se que o restabelecimento das relações e a devida
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'conteudos_tematicos_luta_reparacao' }"
                    >reparação pelos danos sofridos</router-link
                  >
                  em consequência do desastre–crime perpassa obrigatoriamente
                  pela avaliação dos acessos perdidos seja temporária ou
                  definitivamente. Ao longo de quase sete anos da destruição do
                  território, tornou-se urgente que as ações de reparação sejam
                  extensivas a todo o território e não somente pontuais, sendo
                  insuficiente que se reconstruam somente estruturas como
                  currais, galinheiros, chiqueiros, habitações, sem que sejam
                  aplicadas políticas de retomada das relações socioeconômicas
                  envolvendo todo o território atingido. É urgente que seja
                  realizada uma análise aprofundada das redes de produção e
                  escoamento desta, considerando-se as rotas anteriormente
                  percorridas e as consequências que a destruição de vias e
                  pontes trouxeram para a economia dessas comunidades que juntas
                  compõem o território de Mariana.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container bottom--helper">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="11" md="12" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card
              title="PROCESSO DE MAPEAMENTO"
              borderColor="#426E51"
              titleColor="#426E51"
            >
              <template v-slot:conteudo>
                <p>
                  Com o objetivo de evidenciar a situação vivenciada pelas
                  comunidades atingidas e a interrupção das relações sociais,
                  econômicas e culturais devido à perda de acessos, foi criado o
                  mapa das vias utilizadas antes do rompimento e dos desvios
                  adotados depois do desastre criminoso.
                </p>
                <v-dialog
                  v-model="showOverlayer.fig1"
                  class="d-flex justify-center align-center"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      class="mb-0 cursor-pointer mx-auto pink--text"
                      v-bind="attrs"
                      v-on="on"
                      style="font-size: 0.8em"
                    >
                      ampliar imagem
                    </span>
                    <v-icon small color="#000" class="cursor-pointer">
                      mdi-magnify-plus-outline
                    </v-icon>
                  </template>

                  <v-card>
                    <img
                      class="mx-auto"
                      width="100%"
                      src="../../assets/conteudos_vias_acesso_06.png"
                      alt=""
                    />

                    <v-card-actions class="d-flex justify-center align-center">
                      <v-btn
                        color="#edbd43"
                        @click="showOverlayer.fig1 = false"
                      >
                        Fechar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <div class="d-flex flex-column justify-center align-center">
                  <img
                    width="60%"
                    src="../../assets/conteudos_vias_acesso_06.png"
                    alt=""
                    class="mx-auto"
                  />
                  <p style="font-size: 0.6em; text-align: center; width: 50%;">
                    Figura 1: Mapa das vias utilizadas pelas comunidades
                    atingidas antes e após o rompimento da barragem de Fundão.
                    Elaboração: Jackeline Mendes, Ludmilla Novaes e Laura Lanna
                    Carneiro, 2022.
                  </p>
                </div>

                <p class="mt-5">
                  O mapeamento das estradas e trilhas utilizadas pelos atingidos
                  foi feito com participação direta dos moradores das
                  comunidades atingidas pelo rejeito. Inicialmente, foram
                  levantados dados do processo de cadastramento dos atingidos e
                  atingidas pelo rompimento da barragem de Fundão, essas
                  informações foram compiladas pelos assessores da Cáritas MG.
                  Para colher mais informações, os assessores da Cáritas MG
                  fizeram visitas a campo focados em coletar dados das vias do
                  território atingido pelo rompimento. Essas visitas consistiram
                  em percorrer as estradas, realizar registros fotográficos,
                  captar pontos georreferenciados e colher depoimentos de
                  atingidos e atingidas que moram na região. Além da visita ao
                  território, também foram realizadas oficinas por
                  videoconferência com os atingidos e atingidas. Essas oficinas
                  consistiram em apresentar a imagem de satélite e colher
                  informações caminhando virtualmente pelas estradas e trilhas.
                </p>
                <p>
                  Uma prévia do mapa foi elaborada para apresentar durante a
                  reunião da comissão dos atingidos de Mariana. Nesta, os
                  moradores indicaram mudanças necessárias no mapeamento e
                  fizeram considerações sobre a situação das vias no momento e
                  após o rompimento. Foram destacados os problemas no pós
                  rompimento relacionados à mobilidade no território referentes
                  a estradas interditadas, falta da manutenção e irregularidades
                  nas vias. As questões levantadas pelos atingidos e atingidas
                  referentes ao momento do rompimento foram o bloqueio de
                  estradas e a necessidade de utilizar desvios longos para
                  sair/retornar às comunidades atingidas.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container bottom--helper">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="11" md="12" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card
              title="Contexto das comunidades atingidas no território"
              borderColor="#426E51"
              titleColor="#426E51"
            >
              <template v-slot:conteudo>
                <p>
                  Com o objetivo de evidenciar a situação vivenciada pelas
                  comunidades atingidas e a interrupção das relações sociais,
                  econômicas e culturais devido à perda de acessos, foi criado o
                  mapa das vias utilizadas antes do rompimento e dos desvios
                  adotados depois do desastre criminoso.
                </p>

                <v-dialog
                  v-model="showOverlayer.fig2"
                  class="d-flex justify-center align-center"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      class="mb-0 cursor-pointer mx-auto pink--text"
                      v-bind="attrs"
                      v-on="on"
                      style="font-size: 0.8em"
                    >
                      ampliar imagem
                    </span>
                    <v-icon small color="#000" class="cursor-pointer">
                      mdi-magnify-plus-outline
                    </v-icon>
                  </template>

                  <v-card>
                    <img
                      class="mx-auto"
                      width="100%"
                      src="../../assets/conteudos_vias_acesso_07.png"
                      alt=""
                    />

                    <v-card-actions class="d-flex justify-center align-center">
                      <v-btn
                        color="#edbd43"
                        @click="showOverlayer.fig2 = false"
                      >
                        Fechar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <div class="d-flex flex-column justify-center align-center">
                  <img
                    width="100%"
                    src="../../assets/conteudos_vias_acesso_07.png"
                    alt=""
                    class="mx-auto"
                  />
                  <p style="font-size: 0.6em; text-align: center; width: 50%;">
                    Figura 2: Mapa ilustrativo da localização das comunidades do
                    território atingido de Mariana. Cáritas, 2022.
                  </p>
                </div>

                <p class="mt-10">
                  No território atingido de Mariana, situam-se principalmente as
                  comunidades que se formaram ao longo do Rio Gualaxo do Norte,
                  como Bento Rodrigues, Camargos, Bicas, Ponte do Gama, Paracatu
                  de Cima, Paracatu de Baixo, Borba, Pedras e Campinas.
                  Entretanto, não se restringem somente a elas, mas também
                  distritos, subdistritos e localidades implantadas a norte,
                  como é o caso de Santa Rita Durão, Mata do Chaves e Águas
                  Claras, e a sul, como Braúnas e Monsenhor Horta.
                </p>
                <p>
                  Antes do rompimento da barragem de Fundão, os acessos às
                  comunidades ocorriam a partir das vias locais que as
                  conectavam. Vale ressaltar que devido à proximidade entre
                  elas, historicamente essas comunidades possuíam forte vínculo
                  socioeconômico e cultural. As relações construídas ao longo
                  dos séculos desde a formação dos povoados, seja pelos meios de
                  produção, comércio, garimpo e celebrações religiosas, foram
                  interrompidas devido ao desastre-crime.
                </p>
                <p>
                  Na sequência deste texto foram identificadas as rotas de
                  ligação entre as comunidades mais próximas, relatando os
                  pontos e trechos danificados, seja por um determinado período
                  ou definitivamente, e os desvios percorridos após o
                  rompimento. Foram ainda citadas as distâncias das estradas
                  usualmente percorridas pelas comunidades, bem como a
                  quilometragem até a sede de Mariana e a capital do estado,
                  Belo Horizonte.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container bottom--helper">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="11" md="12" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card
              title="MARIANA / BENTO RODRIGUES / SANTA RITA DURÃO"
              borderColor="#426E51"
              titleColor="#426E51"
            >
              <template v-slot:conteudo>
                <p>
                  O distrito de
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'sobre_santa_rita' }"
                    >Santa Rita Durão</router-link
                  >
                  pertence ao município de Mariana/MG e possui Bento Rodrigues e
                  Alegria como subdistritos. Situa-se a 35 km do centro de
                  Mariana e a 150 km de Belo Horizonte, capital do estado de
                  Minas Gerais.
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'bento_rodrigues' }"
                    >Bento Rodrigues</router-link
                  >, por sua vez, localiza-se a cerca de 35 km do centro de
                  Mariana e a 124 km de Belo Horizonte.
                </p>
                <p>
                  A ligação entre Mariana e Santa Rita Durão, em momento
                  anterior ao rompimento da barragem de Fundão, ocorria de duas
                  formas, utilizando-se caminhos de ligação entre a sede de
                  Mariana e o município de Santa Bárbara. Um deles, através da
                  Estrada Real, passava por Camargos e Bento Rodrigues (estrada
                  municipal ARM-130), com 32 km de extensão a partir de Mariana
                  até Santa Bárbara, sem pavimentação.
                </p>
                <p>
                  Devido à interrupção do trecho da Estrada Real passando por
                  Bento, ou seja, pela Rua São Bento, o trajeto de Mariana para
                  Santa Rita Durão passou a ser feito exclusivamente pelo desvio
                  na MG-129, com 45 km de extensão. Além da maior distância,
                  ainda se enfrentam os problemas de tráfego intenso de veículos
                  e maquinários pesados da atividade mineradora.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container bottom--helper">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="11" md="12" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card
              title="CONTEXTO DAS COMUNIDADES ATINGIDAS NO TERRITÓRIO"
              borderColor="#426E51"
              titleColor="#426E51"
            >
              <template v-slot:conteudo>
                <p>
                  O distrito de Camargos pertence ao município de Mariana/MG.
                  Situa-se próximo da Serra do Caraça, na estrada que liga
                  Mariana ao subdistrito de Bento Rodrigues (abrangido
                  administrativamente pelo distrito de Santa Rita Durão). Está
                  distante cerca de 19 km do centro de Mariana e a 135 km da
                  capital Belo Horizonte.
                </p>
                <p>
                  Antes do rompimento da barragem, Camargos fazia parte do
                  circuito turístico da região, que incluía o subdistrito de
                  Bento Rodrigues. Os visitantes eram atraídos principalmente
                  pelas trilhas, cachoeiras, rios e pela comida servida nos
                  restaurantes de fazendas, bem como nos núcleos urbanos de
                  Camargos e Bento. Com a severa destruição sofrida por Bento
                  Rodrigues, que foi completamente assolada pela lama de
                  rejeitos, as atividades advindas dessa interação foram
                  cessadas.
                </p>
                <p>
                  O acesso entre as comunidades era feito através da Estrada
                  Real (estrada municipal ARM-130). O caminho não pavimentado
                  era percorrido através de veículos ou a pé, devido a sua curta
                  distância. Com a passagem da lama, o trecho foi completamente
                  descaracterizado, sendo necessária a utilização de uma via
                  secundária. O atual caminho é monitorado e controlado pelas
                  guaritas da Samarco, que impossibilita o livre acesso e
                  trânsito por Bento Rodrigues. Além da impossibilidade de
                  utilização da via, a estrada apresenta pontos danificados e
                  que haviam sido temporariamente interrompidos.
                </p>
                <p>
                  Vale ressaltar que, devido à destruição da comunidade de Bento
                  Rodrigues e ao controle da localidade pela Samarco, não há
                  meios de transitar entre as demais comunidades a partir de
                  Bento.
                </p>
                <p>
                  As empresas-rés, então, apresentaram duas propostas de ligação
                  entre Camargos e o Reassentamento de Bento Rodrigues
                  (Lavoura). A rota 1 (linha tracejada preta), possui cerca de
                  16 km de extensão, enquanto a rota 2 (linha tracejada
                  vermelha), apresenta aproximadamente 23 km de extensão. Cabe
                  ressaltar que a segunda opção proposta pelas empresas consiste
                  em uma estrada que já existe no território, percorrendo-se um
                  trecho da Estrada Real, utilizada desde o século XVIII. Desta
                  forma, não haveria necessidade de investimento em obras por
                  parte das rés.
                </p>
                <p>
                  Em contrapartida, os atingidos apresentaram duas propostas com
                  menores extensões. Um dos trajetos (linha tracejada marrom)
                  apresenta 13 km de extensão, enquanto o outro (linha tracejada
                  amarela) apresenta cerca de 11 km. A rota de menor distância
                  necessita que seja construída uma ponte para vencer o vão
                  sobre o curso d’água, em ponto próximo à Capela de Nossa
                  Senhora Aparecida. Observa-se que esses dois trajetos se
                  sobrepõem na maior parte do percurso, diferenciando-se no
                  trecho mediano, próximo da referida capela.
                </p>
                <p>
                  O caminho entre a Lavoura e a área de origem (Bento
                  Rodrigues), passando pela Capelinha de Nossa Senhora Aparecida
                  apresenta dois pontos danificados, onde o terreno sofreu
                  deslizamento com as fortes chuvas ocorridas no início de 2022.
                  Diante da falta de providência por parte da Fundação Renova e
                  do poder público municipal em atender à solicitação dos
                  atingidos e atingidas para realizar obras de recuperação na
                  estrada, os próprios moradores com pás, enxadas e picaretas
                  executaram serviços de reparação para poderem transitar.
                </p>

                <v-dialog
                  v-model="showOverlayer.fig3"
                  class="d-flex justify-center align-center"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      class="mb-0 cursor-pointer mx-auto pink--text"
                      v-bind="attrs"
                      v-on="on"
                      style="font-size: 0.8em"
                    >
                      ampliar imagem
                    </span>
                    <v-icon small color="#000" class="cursor-pointer">
                      mdi-magnify-plus-outline
                    </v-icon>
                  </template>

                  <v-card>
                    <img
                      class="mx-auto"
                      width="100%"
                      src="../../assets/conteudos_vias_acesso_08.png"
                      alt=""
                    />

                    <v-card-actions class="d-flex justify-center align-center">
                      <v-btn
                        color="#edbd43"
                        @click="showOverlayer.fig3 = false"
                      >
                        Fechar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <div class="d-flex flex-column justify-center align-center">
                  <img
                    class="mx-auto"
                    width="50%"
                    src="../../assets/conteudos_vias_acesso_08.png"
                    alt=""
                  />
                  <p style="font-size: 0.6em; text-align: center; width: 50%">
                    Figura 3: Quadrante do Mapa ilustrativo das rotas de ligação
                    entre Lavoura e Camargos, onde se visualizam os pontos
                    danificados.
                  </p>
                </div>

                <p class="mt-5">
                  No território atingido de Mariana, situam-se principalmente as
                  comunidades que se formaram ao longo do Rio Gualaxo do Norte,
                  como Bento Rodrigues, Camargos, Bicas, Ponte do Gama, Paracatu
                  de Cima, Paracatu de Baixo, Borba, Pedras e Campinas.
                  Entretanto, não se restringem somente a elas, mas também
                  distritos, subdistritos e localidades implantadas a norte,
                  como é o caso de Santa Rita Durão, Mata do Chaves e Águas
                  Claras, e a sul, como Braúnas e Monsenhor Horta.
                </p>
                <p>
                  Antes do rompimento da barragem de Fundão, os acessos às
                  comunidades ocorriam a partir das vias locais que as
                  conectavam. Vale ressaltar que devido à proximidade entre
                  elas, historicamente essas comunidades possuíam forte vínculo
                  socioeconômico e cultural. As relações construídas ao longo
                  dos séculos desde a formação dos povoados, seja pelos meios de
                  produção, comércio, garimpo e celebrações religiosas, foram
                  interrompidas devido ao desastre-crime.
                </p>
                <p>
                  Na sequência deste texto foram identificadas as rotas de
                  ligação entre as comunidades mais próximas, relatando os
                  pontos e trechos danificados, seja por um determinado período
                  ou definitivamente, e os desvios percorridos após o
                  rompimento. Foram ainda citadas as distâncias das estradas
                  usualmente percorridas pelas comunidades, bem como a
                  quilometragem até a sede de Mariana e a capital do estado,
                  Belo Horizonte.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container bottom--helper">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="11" md="12" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card
              borderColor="#426E51"
              titleColor="#426E51"
              title="CAMARGOS / BICAS"
            >
              <template v-slot:conteudo>
                <p>
                  O subdistrito de Bicas pertence ao Distrito de Camargos, que,
                  por sua vez, faz parte do município de Mariana/MG. Situa-se a
                  15 km de distância do núcleo urbano de Camargos, a 33 km do
                  centro de Mariana e a 139 km de Belo Horizonte.
                </p>
                <p>
                  O trajeto entre Bicas e Camargos era feito por uma estrada de
                  aproximadamente 15 km. Para acessar Bicas, era necessário
                  passar por uma ponte que vencia o vão sobre o Rio Gualaxo do
                  Norte. Após o rompimento da barragem de Fundão, a ponte foi
                  completamente destruída. É importante ressaltar que até a
                  presente data não houve obras de reconstrução da ponte e,
                  consequentemente, o acesso à comunidade tornou-se inviável.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container bottom--helper">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="11" md="12" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card
              borderColor="#426E51"
              titleColor="#426E51"
              title="MARIANA / CAMARGOS / PONTE DO GAMA "
            >
              <template v-slot:conteudo>
                <p>
                  O subdistrito de Ponte do Gama pertence ao distrito de
                  Monsenhor Horta. Situa-se a 7 km de distância do núcleo urbano
                  de Monsenhor Horta e a 27 km do centro de Mariana
                </p>
                <p>
                  . O trajeto para Ponte do Gama pode ser feito a partir de
                  Mariana (27 km), através de Camargos (24 km) ou por Monsenhor
                  Horta (17,5 km), passando por Paracatu de Cima. Após o
                  rompimento da barragem de Fundão, com a queda da ponte de
                  acesso para a comunidade, o local ficou inacessível por
                  aproximadamente três meses. Atualmente, o trecho entre
                  Camargos e Ponte do Gama encontra-se acessível após a
                  reconstrução da ponte. Contudo, é possível observar no mapa
                  ilustrativo abaixo, diversos pontos de interrupção e
                  interrupção temporária entre os trechos de ligação para Ponte
                  do Gama.
                </p>

                <v-dialog
                  v-model="showOverlayer.fig4"
                  class="d-flex justify-center align-center"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      class="mb-0 cursor-pointer mx-auto pink--text"
                      v-bind="attrs"
                      v-on="on"
                      style="font-size: 0.8em"
                    >
                      ampliar imagem
                    </span>
                    <v-icon small color="#000" class="cursor-pointer">
                      mdi-magnify-plus-outline
                    </v-icon>
                  </template>

                  <v-card>
                    <img
                      class="mx-auto"
                      width="100%"
                      src="../../assets/conteudos_vias_acesso_09.png"
                      alt=""
                    />

                    <v-card-actions class="d-flex justify-center align-center">
                      <v-btn
                        color="#edbd43"
                        @click="showOverlayer.fig4 = false"
                      >
                        Fechar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <div class="d-flex flex-column justify-center align-center">
                  <img
                    class="mx-auto"
                    width="50%"
                    src="../../assets/conteudos_vias_acesso_09.png"
                    alt=""
                  />
                  <p style="font-size: 0.6em; text-align: center; width: 50%">
                    Figura 3: Quadrante do Mapa ilustrativo das rotas de ligação
                    entre Lavoura e Camargos, onde se visualizam os pontos
                    danificados.
                  </p>
                </div>
                <p class="mt-5">
                  A lama de rejeitos arrasou as áreas mais baixas de Ponte do
                  Gama, nas margens do Rio Gualaxo do Norte. Com a destruição da
                  ponte que dava acesso ao subdistrito e das estradas, a
                  comunidade ficou isolada sem ter como receber mantimentos,
                  insumos, escoar a produção e manter o contato com os outros
                  locais com as quais possuía relações de cooperação, de trocas
                  e comercialização. Na época do rompimento, até em
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'sobre_aguas_claras' }"
                    >Águas Claras</router-link
                  >, onde conseguiam chegar, as mercadorias ficaram escassas. Os
                  caminhos possíveis como por Fonseca (município de Alvinópolis)
                  apresentavam condições difíceis de transitar, além de serem
                  mais extensos.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container bottom--helper">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="11" md="12" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card
              borderColor="#426E51"
              titleColor="#426E51"
              title="MONSENHOR HORTA / PARACATU DE CIMA"
            >
              <template v-slot:conteudo>
                <p>
                  O distrito de Monsenhor Horta pertence ao município de
                  Mariana/MG e possui como subdistritos Paracatu de Cima,
                  Paracatu de Baixo e Ponte do Gama. Situa-se a cerca de 16 km
                  do centro de Mariana e a 129 km de Belo Horizonte.
                </p>
                <p>
                  A via que liga Monsenhor Horta e Paracatu de Cima possui
                  aproximadamente 9,4 km e foi parcialmente interditada pelo
                  rejeito. A lama destruiu a ponte que dava acesso à porção
                  norte da comunidade e se instalou nas margens ao longo do Rio
                  Gualaxo. Famílias ficaram ilhadas com o bloqueio das vias. Os
                  atingidos e atingidas que moravam na porção sul da comunidade
                  Paracatu de Cima utilizaram trilhas como alternativa para o
                  deslocamento entre comunidades e para escoamento de insumos
                  produzidos nos terrenos atingidos. Já os atingidos e atingidas
                  que moravam na porção norte da comunidade, com a falta de
                  ponte, utilizavam como caminho alternativo para chegar em
                  Mariana a rota que passa pelo distrito Fonseca (Alvinópolis)
                  pegando o acesso pelo trevo de Catas Altas (MG-129). Esse
                  desvio possui aproximadamente 96 km, antes do rompimento, o
                  trecho percorrido para chegar até Mariana era de 31 km. A
                  comunidade de Paracatu de Cima possuía um núcleo urbano
                  composto por sítios, desse modo, as residências estão
                  distribuídas pelo território de forma mais espaçada. A
                  economia da comunidade girava em torno da agricultura, manejo
                  de animais, produção de laticínios e produção de carvão. A via
                  que liga Paracatu de Cima até Monsenhor Horta é importante
                  para escoar esses insumos até os consumidores finais. Essa via
                  ficou parcialmente interditada durante três meses. Atualmente,
                  a via que liga as duas comunidades é utilizada pelos seus
                  moradores e por máquinas que são utilizadas nas obras do
                  reassentamento da comunidade de Paracatu de Baixo e nas obras
                  de recuperação das propriedades do entorno. O trânsito de
                  máquinas pesadas está causando danos na via, são observados
                  buracos e trincas nas estradas, deixando o trânsito inviável
                  em alguns pontos.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container bottom--helper">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="11" md="12" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card
              borderColor="#426E51"
              titleColor="#426E51"
              title="MONSENHOR HORTA / PONTE DO GAMA"
            >
              <template v-slot:conteudo>
                <p>
                  O distrito de Monsenhor Horta pertence ao município de
                  Mariana/MG e possui como subdistritos Paracatu de Cima,
                  Paracatu de Baixo e Ponte do Gama. Situa-se a cerca de 16 km
                  do centro de Mariana e a 129 km de Belo Horizonte.
                </p>
                <p>
                  Quando falamos da estrada que liga as comunidades de Monsenhor
                  Horta a Ponte do Gama é sabido que o rejeito se acumulou nos
                  arredores deste último. Um córrego foi obstruído, e agora a
                  via tem um trecho alagado. A estrada ficou inacessível durante
                  aproximadamente três meses, e o caminho alternativo utilizado
                  para chegar em Mariana foi pelo distrito de Fonseca, também
                  conhecido como Alvinópolis. Isso gerou um enorme transtorno
                  aos moradores que passaram a se deslocar por um desvio por
                  Catas Altas (MG-129). Tal mudança na rota culminou um aumento
                  de 79 km no trajeto que anteriormente era feito em 34,2 km.
                  Algo que também aconteceu na estrada que liga Ponte do Gama a
                  Paracatu de Cima, que passou a ter um trajeto por desvio com
                  88,7 km, percurso que anteriormente era feito em 31,3 km.
                </p>
                <p>
                  Em Ponte do Gama havia registro de apenas um pequeno comércio
                  local, o que reforça a dependência da estrada para relação com
                  outros distritos e a sede, cidade de Mariana. Alunos que saíam
                  do distrito de Ponte do Gama e iam estudar no município sede
                  foram impactados negativamente com o fechamento da estrada, o
                  que impossibilitou o transporte escolar e prejudicou o
                  aprendizado durante o período de manutenção dessas vias. A
                  fonte de renda da comunidade de Ponte do Gama se concentrava
                  em silvicultura, e alguns moradores relataram que precisaram
                  interromper o escoamento da produção de carvão e perderam
                  lenha devido ao estado da estrada. O abandono da Fundação
                  Renova foi tema de várias reuniões na comunidade. Era
                  prometido melhorias na via, o que não aconteceu. A estrada que
                  liga Monsenhor Horta a Ponte do Gama está sem manutenção desde
                  2019 e sofre com o tráfego pesado de caminhões diariamente,
                  apresentando buracos e irregularidades em boa parte de seu
                  percurso.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container bottom--helper">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="11" md="12" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card
              borderColor="#426E51"
              titleColor="#426E51"
              title=" PONTE DO GAMA / PARACATU DE CIMA"
            >
              <template v-slot:conteudo>
                <p>
                  O subdistrito de Ponte do Gama pertence ao Distrito de
                  Monsenhor Horta, assim como Paracatu de Baixo e Paracatu de
                  Cima. Situa-se a 7 km de distância do núcleo urbano de
                  Monsenhor Horta, a 27 km do centro de Mariana e a 140 km de
                  Belo Horizonte.
                </p>
                <p>
                  Paracatu de Cima tem como característica a ruralidade e
                  criação de bovinos leiteiros como fonte de renda do
                  subdistrito. No momento do rompimento o rejeito se acumulou
                  nos arredores da Ponte do Gama. O caminho alternativo para
                  acesso ao distrito sede de Mariana foi pelo distrito de
                  Fonseca (Alvinópolis), pegando o acesso por Catas Altas
                  (MG-129). A distância do trajeto pelo desvio é de 88,7 km,
                  enquanto que no trajeto anterior era de 31,3 km. Tendo como
                  maior característica o caráter rural, Paracatu possui poucos
                  estabelecimentos comerciais, sendo bastante dependente de
                  outros distritos e da sede, Mariana. Com esse longo desvio e
                  com o descaso da Fundação Renova, houve perda da produção de
                  leite e falta de mantimentos para as criações dos moradores
                  que, de modo geral, ficaram sem ração, promovendo o
                  enfraquecimento dos animais.
                </p>
                <p>
                  Atualmente, a estrada é constituída de terra batida. Após o
                  intenso trânsito de veículos pesados a via apresenta buracos e
                  irregularidades.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container bottom--helper">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="11" md="12" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card
              borderColor="#426E51"
              titleColor="#426E51"
              title="PONTE DO GAMA / ÁGUAS CLARAS"
            >
              <template v-slot:conteudo>
                <p>
                  No momento do rompimento – O acesso entre essas comunidades
                  não foi afetado pelo rejeito. Atualmente – A estrada é
                  constituída por terra batida e por asfalto. Após o intenso
                  trânsito de veículos pesados, a via apresenta buracos e
                  irregularidades.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container bottom--helper">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="11" md="12" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card
              borderColor="#426E51"
              titleColor="#426E51"
              title="PARACATU DE CIMA / ÁGUAS CLARAS"
            >
              <template v-slot:conteudo>
                <p>
                  O distrito de Águas Claras pertence ao município de Mariana e
                  possui como subdistritos Campinas e Pedras, que por sua vez
                  tem Borba como povoamento. Situa-se a cerca de 40 km da sede
                  de Mariana e a 155 km de Belo Horizonte.
                </p>
                <p>
                  O subdistrito de Águas Claras possui forte relação com
                  moradores de diversas outras comunidades próximas, com ênfase
                  para Paracatu, tanto de Cima quanto de Baixo. Os moradores
                  frequentavam Águas Claras regularmente, seja para práticas
                  esportivas, seja para festividades e também merece destaque o
                  escoamento de produtores rurais. A via que liga Paracatu de
                  Cima até Águas Claras não foi obstruída pelo rejeito na época
                  do rompimento, porém a ponte de acesso entre essas comunidades
                  e as demais (Paracatu de Baixo, Pedras e Campinas) foi
                  destruída pelo rejeito. Desse modo, a passagem ficou
                  interditada durante aproximadamente três meses. No momento, a
                  estrada é asfaltada e apresenta obstruções devido ao trânsito
                  de veículos pesados.
                </p>
                <p>
                  A via que liga Paracatu de Cima a Águas Claras possui
                  aproximadamente 7,1 km. Essa via foi bloqueada pois a ponte
                  que liga as duas comunidades foi destruída com a passagem de
                  rejeito. Esse trajeto, em novembro de 2015, era utilizado para
                  escoar insumos produzidos pelos produtores rurais da região e
                  trânsito de pessoas, sendo o principal acesso ao distrito
                  Águas Claras. A economia de Águas Claras gira em torno da
                  agricultura, pecuária, produção de laticínios e pequenos
                  empreendimentos. Além das relações econômicas, os atingidos e
                  atingidas das duas comunidades mantinham um calendário repleto
                  de festividades religiosas, esportivas e culturais. O acesso
                  alternativo utilizado pelos atingidos e atingidas era por
                  Fonseca, distrito de Alvinópolis, com distância de 92 km para
                  acessar a comunidade. A ponte entre as duas comunidades foi
                  reconstruída três meses após o rompimento. Atualmente, a via é
                  utilizada para escoar as produções das duas comunidades e
                  também é acessada por máquinas pesadas que pertencem às
                  terceirizadas responsáveis pela reconstrução do território. A
                  via possui algumas irregularidades.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container bottom--helper">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="11" md="12" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card
              borderColor="#426E51"
              titleColor="#426E51"
              title="PARACATU DE CIMA / PARACATU DE BAIXO"
            >
              <template v-slot:conteudo>
                <p>
                  Os subdistritos de Paracatu de Cima e de Paracatu de Baixo,
                  pela divisão administrativa e territorial, pertencem ao
                  Distrito de Monsenhor Horta. Localizam-se a 34 km da sede de
                  Mariana e a 9 km de Monsenhor Horta, a sudeste de Ponte do
                  Gama.
                </p>
                <p>
                  A via que liga Paracatu de Cima e Paracatu de Baixo possui
                  aproximadamente 4 km e foi totalmente interditada pelo
                  rejeito. A lama destruiu a ponte que dava acesso à porção
                  norte da comunidade Paracatu de Cima e se instalou nas margens
                  ao longo do Rio Gualaxo e na via de acesso à comunidade
                  Paracatu de Baixo. Paracatu de Baixo, até novembro de 2015,
                  possuía um núcleo urbano consolidado, desenvolvido a partir da
                  Praça Santo Antônio (em frente à Igreja Santo Antônio), com
                  casas próximas à via pública. A economia do subdistrito girava
                  em torno da agricultura, manejo de animais, produção de
                  laticínios e pequenos empreendimentos. Também existia um vasto
                  calendário com
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{
                      name: 'conteudos_tematicos_religiosidade_calendario',
                    }"
                    >festividades religiosas</router-link
                  >
                  e esportivas. Após o rompimento da barragem de Fundão, as
                  únicas residências que não foram destruídas pela lama são as
                  que estão localizadas na Rua Furquim, uma via com as cotas
                  mais altas.
                </p>

                <div class="d-flex flex-column justify-center align-center">
                  <img
                    class="mx-auto"
                    width="90%"
                    src="../../assets/conteudos_vias_acesso_10.png"
                    alt=""
                  />
                  <p style="font-size: 0.6em; text-align: center; width: 50%">
                    Vista da Rua Furquim em Paracatu de Baixo, onde se observa a
                    altimetria do nível da lama e cotas mais altas. Foto: Flora
                    Passos, 2018.
                  </p>
                </div>

                <p class="ma-0">
                  O acesso às ruínas da comunidade e à Rua Furquim foram
                  completamente interrompidos por aproximadamente três meses.
                  Para acessar a comunidade, os atingidos e atingidas utilizaram
                  dois desvios, o primeiro passando pela comunidade de Furquim
                  (distrito de Mariana), esse trecho possui um total de 9,3 km.
                  O segundo acesso era feito através da Fazenda Braúnas, cujo
                  acesso é através da via entre Monsenhor Horta e Paracatu de
                  Cima, possuindo 9,1 km de extensão. Poucos núcleos familiares
                  permaneceram morando na comunidade após o rompimento. Esses
                  acessos à comunidade foram utilizados para a fuga no dia do
                  rompimento e para voltar e visitar a comunidade devastada.
                </p>
                <p>
                  As comunidades de Paracatu de Baixo e Paracatu de Cima eram
                  bem próximas, existiam trocas econômicas, relações
                  trabalhistas e uso de equipamentos comunitários e públicos.
                  Após a reestruturação da via que liga as duas comunidades, os
                  usos não foram restaurados, pois os territórios e suas
                  relações sociais e econômicas foram destruídas. Os núcleos
                  familiares que ainda moram em Paracatu de Baixo praticam o
                  cultivo de alimentos e manejo de animais para subsistência. De
                  acordo com relatos de moradores e ex-moradores da região, a
                  via que liga as duas comunidades se tornou perigosa devido à
                  falta de poda das matas do entorno, culminando no aparecimento
                  de animais peçonhentos naquela região. Por conseguinte, o
                  isolamento comunitário resultou em um aumento de assaltos na
                  região.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container bottom--helper">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="11" md="12" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card
              borderColor="#426E51"
              titleColor="#426E51"
              title="ÁGUAS CLARAS / CAMPINAS"
            >
              <template v-slot:conteudo>
                <p>
                  O distrito de Águas Claras pertence ao município de Mariana e
                  possui como subdistritos Campinas e Pedras, que por sua vez
                  tem Borba como povoamento. Situa-se a cerca de 40 km da sede
                  de Mariana e a 155 km de Belo Horizonte. É um importante polo
                  comercial da região, sendo que se destaca a Associação dos
                  Produtores de Leite de Águas Claras e Região (Aplacar). Águas
                  Claras também conta com atrativos turísticos como a Cachoeira
                  do Ó e festividades locais como cavalgadas e festas
                  religiosas.
                </p>
                <div class="d-flex flex-column justify-center align-center">
                  <img
                    class="mx-auto mt-3"
                    width="80%"
                    src="../../assets/conteudos_vias_acesso_11.png"
                    alt=""
                  />
                  <p style="font-size: 0.6em; text-align: center; width: 80%">
                    Cachoeira do Ó, localizada na comunidade de Águas Claras.
                    Foto: Paula Zanardi, 2021.
                  </p>
                </div>
                <p class="mt-5">
                  Campinas é composta basicamente por propriedades rurais,
                  voltadas, em sua maioria, para pequenas produções agrícolas e
                  sítios. Existem dois caminhos entre Águas Claras e Campinas. A
                  lama de rejeitos não alcançou as cotas dos caminhos, por isso,
                  o acesso entre elas não foi interrompido.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container bottom--helper">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="11" md="12" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card
              borderColor="#426E51"
              titleColor="#426E51"
              title="CAMPINAS / PEDRAS"
            >
              <template v-slot:conteudo>
                <p>
                  O subdistrito de Campinas pertence ao distrito de Águas
                  Claras, que por sua vez faz parte do Município de Mariana-MG.
                  Situa-se a 9 km de distância do núcleo urbano de Águas Claras,
                  a 47 km do centro de Mariana e a 159 km de Belo Horizonte.
                </p>
                <p>
                  A via que liga Campinas a Pedras possui aproximadamente 7,4
                  km. Essa via foi bloqueada, pois a ponte que liga as duas
                  comunidades foi destruída com a passagem do rejeito. Campinas
                  e Pedras são comunidades rurais que contam com sítios no
                  entorno da estrada que liga as duas comunidades. O trajeto era
                  utilizado para escoar insumos produzidos nos terrenos e no
                  trânsito de pessoas. A economia de ambas as comunidades gira
                  em torno da agricultura, pecuária, produção de laticínios e
                  pequenos comércios. Devido ao rompimento, os moradores de
                  Campinas utilizavam o acesso alternativo por Fonseca, distrito
                  de Alvinópolis, e a quilometragem para chegar até Mariana era
                  de 91,2 km. Os moradores de Pedras ficaram ilhados após o
                  rompimento da barragem de Fundão. Foi necessário utilizar uma
                  trilha para sair da comunidade. A ponte e a estrada que ligam
                  as duas comunidades ficaram obstruídas por aproximadamente
                  três meses. Atualmente, a via é utilizada para escoar produção
                  dos sítios das duas comunidades e também por máquinas pesadas
                  que pertencem às terceirizadas responsáveis pela reconstrução
                  do território. A via possui irregularidades (buracos,
                  desníveis e fissuras).
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container pb-10 mt-4 mb-section">
      <v-row class="d-flex">
        <v-col cols="12">
          <div class="inside__card">
            <p class="ma-0">
              <strong>Autores:</strong> Ana Paula Alvez Ferreira<sup>2</sup>,
              Hudson Henrique Ferreira Faria<sup>3</sup> e Marina Miranda de
              Faria<sup>4</sup>.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container pb-10 mt-4 mb-section">
      <v-row class="d-flex">
        <v-col cols="12">
          <div class="inside__card">
            <p><strong> Referências Bibliográficas </strong></p>
            <p>
              LIMA, Kleverson Teodoro.
              <strong>Relatório Técnico de História</strong>. Diagnóstico
              Preliminar dos Bens Culturais Identificados no Territ’prio
              Atingido em Mariana pelo Rompimento da Barragem de Fundão. Pólen
              Consultoria Patrimônio e Projetos LTDA, 2017.
            </p>
            <p>
              LIMA, Maryellen., Milena.
              <strong>“Aqui não é o nosso lugar”</strong>: Efeitos
              socioambientais, a vida provisória e o processo de (re)construção
              de Paracatu de Baixo, Mariana/MG. 116 f. Monografia apresentada ao
              curso de (Graduação em Ciências Socioambientais) — da Faculdade de
              Filosofia e Ciências Humanas da Universidade Federal de Minas
              Gerais, Minas Gerais, 2018. Disponível em:
              <a
                class="pink--text"
                href="https://conflitosambientaismg.lcc.ufmg.br/wp-content/uploads/2019/06/MONOGRAFIA-Maryellen5.pdf"
                style="text-decoration: none;"
                >https://conflitosambientaismg.lcc.ufmg.br/wp-content/uploads/2019/06/MONOGRAFIA-Maryellen5.pdf</a
              >.
            </p>
            <p>
              RAMBOLL. <strong>Relatório de Monitoramento Mensal</strong> – Mês
              052, Abril/2021.
            </p>
            <p>
              <sup>2</sup>Arquiteta e Urbanista pela USP. Assessora Técnica na
              ATI Mariana.
            </p>
            <p class="mb-0">
              <sup>3</sup>Arquiteto e Urbanista pela UFOP. Assessor Técnico na
              ATI Mariana.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
import Breadcrumb from "@/layout/Breadcrumb";

export default {
  components: { Card, Breadcrumb },
  data() {
    return {
      showOverlayer: {
        fig1: false,
        fig2: false,
        fig3: false,
        fig4: false,
      },
      breadcrumbItems: [
        {
          text: "< Conteúdos Temáticos /",
          href: "conteudos_tematicos",
        },
        {
          text: "Casas /",
          href: "conteudos_tematicos_casas",
        },
        {
          text: "A vida provisória",
          href: "conteudos_tematicos_casas_vida_provisoria",
        },
      ],
    };
  },
};
</script>

<style scoped>
.bottom--helper {
  margin-bottom: 3em;
}

.pointer {
  cursor: pointer;
}

p {
  font-size: 0.6em;
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
}

.inside__card p {
  font-size: 0.5em;
}
</style>
